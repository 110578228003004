import React from "react"
import Helmet from "react-helmet"
import { StaticQuery, graphql, Link } from "gatsby"
import loadable from "@loadable/component"

//import Navbar from "../components/Navbar"
import Footer from "../components/Footer"

import "leaflet/dist/leaflet.css"
import "./Styles/Custom.scss"
import favicon from "../img/favicon.ico"
const Navbar = loadable(() => import("../components/Navbar"))
//import './all.sass'

const TemplateWrapper = ({ children, location, election }) => (
  <div>
    <Helmet>
      <html lang="en" />
      {/* <title>{data.site.siteMetadata.title}</title>
      <meta name="description" content={data.site.siteMetadata.description} />
      <meta name="theme-color" content="#fff" />
      <meta property="og:type" content="business.business" />
      <meta property="og:title" content={data.site.siteMetadata.title} />
      <meta property="og:url" content="/" />
      <meta property="og:image" content="/img/maxresdefault.jpg" /> */}

      <meta name="theme-color" content="#fff" />
      <meta property="og:type" content="business.business" />
      <meta
        property="og:image"
        content={`https://globelynx.com/img/maxresdefault.jpg`}
      />

      <link rel="icon" href={favicon} type="image/x-icon" />

      <link rel="shortcut icon" href={favicon} type="image/x-icon" />

      <link
        rel="preload"
        as="style" 
        type="text/css"
        onload="this.onload=null;this.rel='stylesheet'"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />

      <link 
          rel="preload"
          href="https://use.fontawesome.com/releases/v5.6.1/css/all.css"
          as="style" 
          onload="this.onload=null;this.rel='stylesheet'"/>

      <link
        rel="preload"
        as="style" 
        onload="this.onload=null;this.rel='stylesheet'"
        href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.7.0/animate.min.css"
      />


      {/* <script>
        {
          `function init() {
          var vidDefer = document.getElementsByTagName('iframe');
          for (var i=0; i<vidDefer.length; {
          if(vidDefer[i].getAttribute('data-src')) {
                    vidDefer[i].setAttribute('src', vidDefer[i].getAttribute('data-src'));
          } } }
          window.onload = init;`
        }
      </script> */}


      <style>
        {`@media only screen and (max-width : 800px) {
         .modal-content {
           width: 80% !important;
         }
        }

        .grecaptcha-badge {
            visibility: hidden;
        }

          @font-face {
            font-display: swap;
          }

          .slick-arrow { 
            display: none !important
          }

        `}
      </style>


      {/* 
      <link rel="stylesheet" href={LiteYoutubeCSS} />
      <script src={LiteYoutubeJS}></script>
   */}


      <script defer>
        {`window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'UA-157167489-2');`}
      </script>

      <script defer>
        {`if (window.NodeList && !NodeList.prototype.forEach) {
      NodeList.prototype.forEach = Array.prototype.forEach;
      }`}
      </script>

    </Helmet>
    <Link
      id="made-by"
      to="/contact"
      className={`${location === "dashboard" ? "is-display-none" : ""}`}
    >
      JOIN THE COMMUNITY
    </Link>
    <Navbar location={location} election={election} />
    <div>{children}</div>
    <Footer location={location} />
  </div>
)

export default TemplateWrapper
